'use client'

import {Amplify, ResourcesConfig} from 'aws-amplify'
import {
  configureAutoTrack,
  enable as enableAnalytics,
} from 'aws-amplify/analytics'
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito'
import {usePathname, useSearchParams} from 'next/navigation'
import {ReactNode, Suspense, useEffect} from 'react'
import ReactGA from 'react-ga4'

import RouterProvider from '@/providers/react-aria-router.provider'
import {getAmplifyConfig} from '@/utils/amplify'
import {cookieStorage} from '@/utils/cookie-storage'
import {getQueryClient} from '@atorie/core/query-client'
import {AuthProvider} from '@atorie/hooks/providers'
import {QueryClientProvider} from '@tanstack/react-query'

import 'aws-amplify/auth/enable-oauth-listener'

import config from '../../../../amplifyconfiguration.json'

// Single configure call with all configs
Amplify.configure(
  {
    ...getAmplifyConfig(config),
  } as ResourcesConfig,
  {ssr: true},
)

cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage)

// Enable analytics after configuration
enableAnalytics()

configureAutoTrack({
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // REQUIRED, the event type, it's one of 'event', 'pageView' or 'session'
  type: 'session',
  // OPTIONAL, additional options for the tracked event.
  options: {
    // OPTIONAL, the attributes of the event
    attributes: {
      customizableField: 'attr',
    },
  },
})

export interface AppProviderProps {
  children: ReactNode
}

const usePageTracking = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    ReactGA.initialize('G-XX87QNN2R6')

    const trackPageView = () => {
      const page = `${pathname}${searchParams ? '?' + searchParams.toString() : ''}`
      ReactGA.send({hitType: 'pageview', page})
    }

    trackPageView() // Track the initial page load
  }, [pathname, searchParams]) // Trigger whenever the pathname or search params change
}

function GATrackPageView() {
  usePageTracking()
  return null
}

export function AppProvider({children}: AppProviderProps) {
  const queryClient = getQueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RouterProvider>
          <Suspense>
            <GATrackPageView />
            {children}
          </Suspense>
        </RouterProvider>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default AppProvider
