import {CookieStorage} from 'aws-amplify/utils'

// Create cookie storage configuration
export const cookieStorage = new CookieStorage({
  domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN ?? 'localhost',
  path: '/',
  expires: 365,
  secure: process.env.NEXT_PUBLIC_COOKIE_DOMAIN !== 'localhost',
  sameSite: 'lax',
})

export const clearCognitoCookies = () => {
  const cookies = document.cookie.split('; ')
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    if (name.includes('CognitoIdentityServiceProvider')) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${process.env.NEXT_PUBLIC_COOKIE_DOMAIN}`
    }
  }
}
