import {useRouter} from 'next/navigation'

import {RouterProvider as RACRouterProvider} from '@atorie/ui/rac'

declare module '@atorie/ui/rac' {
  interface RouterConfig {
    routerOptions: NonNullable<
      Parameters<ReturnType<typeof useRouter>['push']>[1]
    >
  }
}

interface RouterProviderProps {
  children: React.ReactNode
}

export function RouterProvider({children}: RouterProviderProps) {
  const router = useRouter()

  return (
    <RACRouterProvider navigate={(...args) => router.push(...args)}>
      {children}
    </RACRouterProvider>
  )
}

export default RouterProvider
